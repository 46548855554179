import { Box, Flex, List, ListIcon, ListItem, Text, Stack } from '@chakra-ui/react'
import React from 'react'
// import isArray from 'lodash/isArray';
// import { VscCircleFilled } from "react-icons/vsc";
import { SwiperSlide } from 'swiper/react';

import BackgroundImage from '../../../components/BackgroundImage'
import { responsive } from '../../../contexts/responsive';
import SwiperModule from '../../../components/Swiper';
import Case, { listModule } from './Case';

import know from './know.png';
import ui from './ui.png';
import hard from './hard.png';
import diff from './difficult.png';
import apple from './apple.png';
import cc from './cc.png';
import tt from './tt.png';
import angel from './angel.png';
import oo from './oo.png';
import sy from './sy.png';

const ways = [
  // {
  //   title: '為何陪伴孩子成長這麼難？',
  //   list: ['花許多時間陪伴，卻還是不了解孩子的想法', '孩子遇到不如意的事情就大哭大鬧，難以溝通', '照著書上的方法養孩子，卻沒有產生改變'],
  //   src: diff,
  //   ratio: 692 / 616,
  //   ui: ui,
  //   uiRatio: 248 / 343
  // },
  {
    title: '父母的不容易，跳養都懂',
    subTitle: '這些挑戰，都和孩子天生的氣質與天賦有關',
    list: [' 保持開放的心認識你的孩子', ' 了解後就能找到關鍵因素適性教養', ' 陪伴與引導孩子找到真正的自己'],
    src: hard,
    ratio: 694 / 668,
    deco: know,
    decoRatio: 792 / 780
  },
  // {
  //   title: '透過玩出天賦的三大教養方案\n和你一起發掘孩子的氣質與天賦',
  //   list: '透過10多年實體授課的紮實經驗，結合10多種兒童教養相關領域，發展出AI系統來幫助你陪伴孩子獲得更好的成長！'
  // },
]

const sliders = [
  {
    text: '「生小孩沒有使用說明書，告訴你這個小孩是圓的扁的、怎麼使用，但這個App會告訴你孩子的特質、可能發生的狀況、可以怎麼避免，會讓我很安心！」',
    src: apple,
  },
  {
    text: '「在使用三個月之後，他開始願意嘗試以前不敢玩的玩具或遊樂器材，應對方式也變得更加成熟，開始慢慢改變跟別人的相處方式，跟人家的互動也變好。」',
    src: cc,
  },
  {
    text: '「我透過跳養看見孩子不同的一面，是我以前不曾發現過的；也讓我看見孩子現在的心理狀態與心情，到底有多難過、傷心或快樂。」',
    src: tt,
  },
  { text: '「兩姐妹實在是我們目前遇到最大的教養問題，難以解決姐妹之間的紛爭；在使用跳養、並接受建議後，我們發現孩子開始願意嘗試以前不敢做的事，在跟我們之間的互動時，情緒上也比較不會大起大落。」',
    src: angel,
  },
  { text: '「跳養建議我們讓責任回歸孩子身上，我們帶著他去做，讓他把事情做好，一到兩天小朋友的狀況有明顯改善，老師也給了很正向的回饋！」',
    src: oo,
  },
  { text: '「在知道他有情緒問題後，沒有任何人願意幫助我們，我知道我的孩子沒錯，只是我不知道該如何幫助孩子；在上網求助各種資源後，終於找到了這個團隊，幫助我的孩子釋放情緒、讓他發現他其實很棒！」',
    src: sy,
  },
]

const Way = ({ cart, addToCart }) => {
  return (
    <Box pos="relative" pt="35%">
      <Box bg="custom.bgYellow" px={responsive('15px')} pb="5.5em">
        <Box bg="custom.skin" pt="22.5%" pb={responsive('2em', '3em')} borderRadius="1em">
          <Stack spacing={6}>
          {ways.map((way, i) => (
            <Box pos="relative" key={i}>
              <Box><BackgroundImage src={way.src} ratio={way.ratio} /></Box>
              {/* {way.ui && (
                <Box pos="absolute" top="-1.5em" right="-1em" width="5.125em"><BackgroundImage src={way.ui} ratio={way.uiRatio} /></Box>
              )} */}
              <Flex mt="0.625rem" pl="2rem" pr="1.25rem" alignItems={'center'}>
                <Box flex={1} as={List} spacing={1} fontSize={responsive('0.875em', '1.125em')} color="custom.listColor">
                  {way.list.map(listModule)}
                </Box>
                {way.deco && (
                  <Box width="4.5em"><BackgroundImage src={way.deco} ratio={way.decoRatio} /></Box>
                )}
              </Flex>
            </Box>
          ))}
          </Stack>
        </Box>
        <Case cart={cart} addToCart={addToCart} />
        <Box id="suggest" mt={responsive('3.75em', '5.5em')} pos="relative">
          <SwiperModule>
            {sliders.map(({text, src}, i) => (
              <SwiperSlide style={{ height: 'auto' }} key={i}>
                <Box bg="custom.skin" height={'100%'} overflow={'hidden'} borderRadius="1.25em">
                  <Box><BackgroundImage src={src} ratio={345 / 180} /></Box>
                  <Box
                    fontSize={responsive('0.875em', '1.125em')}
                    px={responsive('1.625rem', '2.5em')}
                    pt={responsive('0.75rem', '1.25em')}
                    pb={responsive('1.75rem', '1em')}
                  >{text}</Box>
                </Box>
              </SwiperSlide>
            ))}
          </SwiperModule>
          <Box
            pos="absolute"
            fontWeight={'bold'}
            top="0"
            left={'50%'}
            px={responsive('0.875em', '1.375rem')}
            py={responsive('0.5rem', '1.125rem')}
            bg="custom.skyBlue"
            transform="translate(-50%, -50%)"
            color="white"
            borderRadius="1.25em"
            fontSize={responsive('1.5em', '2em')}
            width={responsive('227px', '348px')}
            textAlign="center"
            zIndex={10}
          >家長推薦</Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Way
