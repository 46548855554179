import React from 'react'
import { useSessionStorage } from 'react-use';
import { Box, IconButton, Circle } from '@chakra-ui/react'
import { GiShoppingCart } from "react-icons/gi";

import { responsive } from '../../contexts/responsive';
import BackgroundImage from '../../components/BackgroundImage';
import mobile_bg from './mobile_bg.jpg';
import Way from './Way';
import way_bg from './Way/way_bg.png';
import Link from '../../components/Link';
import { addToCartEvent } from '../Cart/gaEvents';

const IndexPage = () => {
  const [cart, addToCart] = useSessionStorage('cart', [])
  const enhancedAddToCart = (itemId) => {
    addToCart(c => [...c, itemId])
    addToCartEvent(itemId)
  }
  return (
    <Box pos="relative" maxW="36em" mx="auto">
      {!!cart.length && (
        <Box textAlign={'right'} pos="sticky" top={responsive("7.25em", '8.625rem')} zIndex={99} pointerEvents="none">
          <Box
            display={'inline-block'}
            borderRadius={'50% 0 0 50%'}
            p="0.75em 0.5em 0.75em 1.375em"
            bg="white"
            as={Link}
            to="/cart"
            boxShadow="-1px 1px 4px rgba(0, 0, 0, 0.25), inset 0px 0px 1px rgba(0, 0, 0, 0.25)"
            pointerEvents="all"
          >
            <Box pos="relative">
              <IconButton
                px="0"
                minW="auto"
                bg="transparent"
                color="custom.skyBlue"
                icon={<GiShoppingCart size="2em" />}
                _hover={{ bg: 'transparent' }}
                transform={'rotateY(180deg)'}
              />
              <Circle
                top="0"
                left="50%"
                pos="absolute"
                color="white"
                width="1.125em"
                height="1.125em"
                bg="custom.red"
                transform={'translate(-60%, -25%)'}
              >
                {cart.length}
              </Circle>
            </Box>
          </Box>
        </Box>
      )}
      <Box pos="relative" mt={responsive('-3.75em', '-4.5em')}>
        <Box pos="absolute" top={0} right={0} left={0} bottom={0}>
          <BackgroundImage src={mobile_bg} ratio={1125 / 1647} />
        </Box>
        <Box ml="10%" fontSize={responsive('1.5em', '2em')} letterSpacing="0.1em" fontWeight={'bold'} pt="75%" pos="relative">
          把握暑假黃金期<br/>
          和孩子一起玩出天賦的<br/>
          三大教養方案
        </Box>
        <Box pos="absolute" left="0" right="0" bottom="-50%" zIndex={5}>
          <BackgroundImage src={way_bg} ratio={1125 / 594} />
        </Box>
      </Box>
      <Way cart={cart} addToCart={enhancedAddToCart} />
    </Box>
  )
}

export default IndexPage
